import axios from "../../../utility/api"

const locationConfig = (item = false) => {
  return {
    id: item ? item.id : 0,
    name: item ? item.name : ""
  }
}

const placeDetails = (item = false) => {
  return {
    id: item ? item.id : 0,
    name: item ? item.name : "",
    type: item ? item.type : "",
    systemType: item ? item.systemType : "",
  }
}

const placeAnalytics = (item = false) => {
  return {
    doorOpeningsDistribution: item ? item.doorOpeningsDistribution : [],
    doorOpeningsPerUser: item ? item.doorOpeningsPerUser : [],
    users: item ? item.users : [],
    lockers: item ? item.lockers : [],
    reports: item ? item.reports : [],
  }
}

const availableLockersBySize = (lockers = false) => {
  var lockersBySize = {'S': 0, 'M': 0, 'L': 0}
  lockers.map(locker => {
    if(locker.active_delivery.id === undefined && locker.locker_users.length === 0){
      lockersBySize[locker.size]++
    }else{
      return false
    }
  })
  return lockersBySize
}

export const getPlaces = (token, reset = false) => (dispatch) =>
new Promise(function(resolve, reject){
  if (reset) {
    dispatch({
      type: "GET_PLACES_SUCCESS",
      payload: false
    })
  }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/places')
    .then(response => {
      if (response.data) {
        if (response.data.ResultCode === 1)  {
          dispatch({
            type: "GET_PLACES_SUCCESS",
            payload: response.data.Data
          })
          resolve()
        }else{
          reject()
          throw(response);
        }
      }else{
        reject()
        throw(response);
      }
    })
    .catch(err => reject())
})

export const getPlaceDetails = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_PLACE_DETAILS_SUCCESS",
        payload: false
      })
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/PlaceDetails/'+id).then(res => {
      console.log(res)
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_PLACE_DETAILS_SUCCESS",
          payload: placeDetails(res.data.Data.Place)
        })
        resolve()
      } else {
        reject()
        throw(res);
      }
    }).catch(err => {
      reject()
      console.log(err)
    })
})

export const setSelectedPlace = place => {
  return dispatch => {
    dispatch({
      type: "SET_SELECTED_PLACE",
      payload: place
    })
  }
}

export const getLockersByPlace = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject){
    if (reset) {
      dispatch({
        type: "GET_LOCKERS_BY_PLACE_SUCCESS",
        payload: false
      })
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/lockersByPlace/' + id)
    .then(response => {
      console.log(response)
      if (response.data) {
        if (response.data.ResultCode===1) {
          dispatch({
            type: "GET_LOCKERS_BY_PLACE_SUCCESS",
            payload: response.data.Data
          })
          resolve()
        }else{
          reject()
        }
      }else{
        reject()
      }
    })
    .catch(err => reject())
})

export const getDeliveryLockers = (token, id1, id2, id3, reset = false) => (dispatch) =>
new Promise(function(resolve, reject){
    if (reset) {
      dispatch({
        type: "GET_DELIVERY_LOCKERS_SUCCESS",
        payload: false
      })
    }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/deliveryLockers?locationId1='+ id1 + '&locationId2=' + id2 + '&locationId3=' + id3)
    .then(response => {
      console.log(response)
      if (response.data) {
        if (response.data.ResultCode===1) {
          dispatch({
            type: "GET_DELIVERY_LOCKERS_SUCCESS",
            payload: response.data.Data
          })
          resolve()
        }else{
          reject()
        }
      }else{
        reject()
      }
    })
    .catch(err => reject())
})

export const getPlaceAnalytics = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_PLACE_ANALYTICS_SUCCESS",
        payload: placeAnalytics()
      })
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/dashboard/place/'+id).then(res => {
      console.log(res)
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_PLACE_ANALYTICS_SUCCESS",
          payload: placeAnalytics(res.data.Data)
        })
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
    })
})

export const updateLockerStatus = (item) => (dispatch) =>
new Promise(function(resolve, reject) {
  dispatch({
    type: "UPDATE_LOCKER_STATUS_SUCCESS",
    payload: item
  })
  resolve()
})

export const getLockersBySize = (token, reset = false) => (dispatch) =>
new Promise(function(resolve, reject){
    if (reset) {
      dispatch({
        type: "GET_LOCKERS_BY_SIZE_SUCCESS",
        payload: false
      })
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/lockersByPlace/' + process.env.REACT_APP_RENTAL_LOCATION_ID)
    .then(response => {
      console.log(response)
      if (response.data) {
        if (response.data.ResultCode===1) {
          dispatch({
            type: "GET_LOCKERS_BY_SIZE_SUCCESS",
            payload: availableLockersBySize(response.data.Data.Lockers)
          })
          resolve()
        }else{
          reject()
        }
      }else{
        reject()
      }
    })
    .catch(err => {
      console.log(err)
      reject()
    })
})

export const getLocationConfig = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_LOCATION_CONFIG_SUCCESS",
        payload: false
      });
    }

    if (id==="new") {
      dispatch({
        type: "GET_LOCATION_CONFIG_SUCCESS",
        payload: locationConfig()
      });
      return;
    }
    /*axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/user/'+id).then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_USER_DETAILS_SUCCESS",
          payload: userDetails(res.data.Data)
        });
      } else {
        reject()
        throw(res);
      }
    }).catch(err => {
      reject()
      console.log(err)
    })*/
})
