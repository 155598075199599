import axios from "../../../utility/api"
import moment from 'moment'

const orderDetails = (item = false) => {
  return {
    id: item ? item.id : 0,
    createdAt: item ? item.createdAt : "",
    createdBy: item ? item.createdBy : "",
    deliveryType: item ? item.deliveryType : "",
    packageId: item ? item.packageId : "",
    recipientEmail: item ? item.recipientEmail : "",
    recipientName: item ? item.recipientName : "",
    recipientNumber: item ? item.recipientNumber : "",
    recipientPhone: item ? item.recipientPhone : "",
    recipientUpn: item ? item.recipientUpn : "",
    state: item ? item.state : "",
    notes: item ? item.notes : "",
    treatment: item ? (item.treatment !== 'donation' && item.treatment !== 'destroyed' ? 'other' : item.treatment) : "",
    treatmentValue: item ? (item.treatment === 'donation' || item.treatment === 'destroyed' ? '' : item.treatment) : "",
    assignedLocker: (item && item.assignedLocker) ? item.assignedLocker : [],
    assignedLockerUser: (item && item.assignedLockerUser) ? item.assignedLockerUser : [],
    logs: (item && item.logs) ? item.logs : [],
    pickUpAt: item ? item.pickUpAt : "",
    storedAt: item ? item.storedAt : "",
  }
}

export const getOrders = (token, /*page, rowsPerPage, filters,*/max_results, reset, userId) => (dispatch) =>
  new Promise(function(resolve, reject) {
    /*const offset = rowsPerPage * (page - 1)
    let filterString = ''
    Object.entries(filters).forEach(([k, v]) => {
      if(v != null && v != ''){
        filterString = filterString + `&${k}=${v}`
      }
    })*/
    if (reset === true) {
      dispatch({
        type: "GET_ORDERS_LIST_SUCCESS",
        payload: false
      })
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&state=1,2,3,4,5,6,7,8,9,10,11,12,13&archived=false'+'&lockerUserId=' + userId)
    .then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        let ordersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ORDERS_LIST_SUCCESS",
          payload: {orders: ordersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      reject()
    })
})

export const getArchivedOrders = (token, /*page, rowsPerPage, filters,*/max_results, reset, userId) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ARCHIVED_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&state=14&archived=false'+'&lockerUserId=' + userId)
    .then(response => {
      if (response.data.ResultCode===1) {
        let archivedOrdersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ARCHIVED_ORDERS_LIST_SUCCESS",
          payload: {archivedOrders: archivedOrdersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
})

export const getOrdersHistory = (token, max_results,/*page, rowsPerPage, filters,*/ reset, userId) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&archived=true'+'&lockerUserId=' + userId)
    .then(response => {
      if (response.data.ResultCode===1) {
        let ordersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ORDERS_LIST_SUCCESS",
          payload: {orders: ordersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      reject()
    })
})

export const getOrderDetails = (token, id, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: false
      });
    }

    if (id==="new") {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: orderDetails()
      });
      return;
    }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/order?packageId='+id).then(res => {
      console.log(res)
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        if(res.data.Data.state !== 1 && res.data.Data.state !== 2){
          resolve('orderDelivered')
        }else{
          resolve()
        }
      } else {
        reject('orderNotExist')
      }
    }).catch(err => {
      reject()
    })
})

export const setOrderDetails = (token,order,treatment) => (dispatch) =>
new Promise(function(resolve, reject){
  
  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  if (order.id === 0) {
    axios.post('api/delivery/order', {
      packageId: order.packageId,
      recipientName: order.recipientName,
      recipientNumber: order.recipientNumber,
      recipientEmail: order.recipientEmail,
      recipientPhone: order.recipientPhone,
      lockerUserId: order.lockerUserId ? order.lockerUserId : null ,
    })
    .then(res => {
      console.log(res)
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      console.log(err)
      reject(err)
    })
  } else {
    axios.put('api/delivery/order', {
      id: order.id,
      packageId: order.packageId,
      recipientName: order.recipientName,
      recipientNumber: order.recipientNumber,
      recipientEmail: order.recipientEmail,
      recipientPhone: order.recipientPhone,
      notes: order.notes,
      treatment: treatment
    })
    .then(res => {
      if (res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      reject()
    })
  }
})

export const assignLocker = (token, locationId, packageId, size, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
    if (reset) {
      dispatch({
        type: "GET_ORDER_DETAILS_SUCCESS",
        payload: false
      });
    }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/assignLocker?locationId='+locationId+'&size='+size+'&packageId='+packageId).then(res => {
      if (res.data.ResultCode && res.data.ResultCode===1) {
        dispatch({
          type: "GET_ORDER_DETAILS_SUCCESS",
          payload: orderDetails(res.data.Data)
        });
        return resolve(res.data)
      }else if(res.data.Description && res.data.Description === 'No locker avaliable'){
        reject('No locker available')
      }else {
        reject()
      }
    }).catch(err => {
      reject()
    })
})

export const getLockers = (token, reset = false) => (dispatch) =>
new Promise(function(resolve, reject) {
      if (reset) {
        dispatch({
          type: "GET_ORDER_PLACES_LOCKERS",
          payload: false
        })
      }

      axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
      axios.get('api/julian/lockers')
      .then(res => {
        if (res.data.ResultCode===1) {
          let lockers = res.data.Data && Object.keys(res.data.Data).reduce(function (r, a) {
            r[a] = res.data.Data[a]
            return r
          }, {})

          dispatch({
            type: "GET_ORDER_PLACES_LOCKERS",
            payload: lockers
          })
          resolve()
        }else{
          reject()
          throw(res)
        }
      }).catch(err => {
        reject()
      })
})

export const deleteOrder = (token, order) => (dispatch) =>
new Promise(function(resolve, reject) {
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.delete('api/delivery/order', {data: { packageId: order.packageId }}).then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        resolve()
      } else {
        reject()
      }
    }).catch(err => {
      reject()
      console.log(err.response)
    })
})


export const getOrdersAnalytics = (token, id, dateRange) => (dispatch) =>
new Promise(function(resolve, reject) {
  
  var startDate = '';
  var endDate = '';

  if(dateRange){
    if(dateRange.length === 2){
      startDate = moment(dateRange[0]).format('YYYY-MM-DD 00:00:00');
      endDate = moment(dateRange[1]).format('YYYY-MM-DD 23:59:59');
    }
  }

  axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
  axios.get(`api/delivery/analytics?locationId=${id}&startDate=${startDate}&endDate=${endDate}`)
  .then(response => {
    console.log(response)
    if (response.data.ResultCode === 1) {
      dispatch({
        type: "GET_ORDERS_ANALYTICS_SUCCESS",
        payload: response.data.Data
      })
      resolve()
    } else {
      reject()
      throw(response);
    }
  })
  .catch(err => {
    reject()
  })
})

export const getDepositedOrders = (token, /*page, rowsPerPage, filters,*/max_results, reset, userId) => (dispatch) =>
new Promise(function(resolve, reject) {
  /*const offset = rowsPerPage * (page - 1)
  let filterString = ''
  Object.entries(filters).forEach(([k, v]) => {
    if(v != null && v != ''){
      filterString = filterString + `&${k}=${v}`
    }
  })*/
  if (reset === true) {
    dispatch({
      type: "GET_ORDERS_LIST_SUCCESS",
      payload: false
    })
  }

    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/delivery/listOrders?max_results='+max_results+/*+rowsPerPage+'&offset='+offset+filterString+*/'&state=4&archived=false'+'&lockerUserId=' + userId)
    .then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        let ordersList = response.data.Data.deliveries.map((item, i) => {
          return orderDetails(item)
        })
        dispatch({
          type: "GET_ORDERS_LIST_SUCCESS",
          payload: {orders: ordersList, maxRows: response.data.Data.totalDeliveries}
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      reject()
    })
})