import axios from "../../../utility/api"

const alertsDetails = (item = false) => {
  return {
    id: item ? item.id : 0,
    location: item ? item.location_id : "",
    location_name: item ? item.location_name : "",
    place_id: item ? item.place_id : "",
    place_name: item ? item.place_name : "",
    users: item ? item.users : "",
    locker_metra_id: item ? item.locker_metra_id : "",
    locker_metra_status: item ? item.locker_metra_status : "",
    locker_metra_status_updated_at: item ? item.locker_metra_status_updated_at : "",
    expiration_date_time: item ? item.expiration_date_time : "",
    status: item ? item.status : "",
    size: item ? item.size : "",
    enabled: item ? item.enabled : "",
  }
}

const expiredCounter = (item = false) => {
  return {
    delivery: item ? item.delivery : 0,
    office: item ? item.office : 0,
  }
}

export const getAlerts = (token, reset) => (dispatch) =>
new Promise(function(resolve, reject){
  if(reset){
    dispatch({
      type: "GET_ALERTS_SUCCESS",
      payload: false
    })
  }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/dashboard/getNotClosedLockers')
    .then(response => {
      console.log(response)
      if (response.data.ResultCode===1) {
        //temporary filter to exclude location 3 and 6
        //let alertsList = response.data.Data.filter(item => item.location_id != 3 && item.location_id != 6)
        //alertsList = alertsList.map((item, i) => {
          //return alertsDetails(item)
        //})
        //then return to using this code:
        let alertsList = response.data.Data.map((item, i) => {
          return alertsDetails(item)
        })
        dispatch({
          type: "GET_ALERTS_SUCCESS",
          payload: alertsList
        })
        resolve()
      } else {
        reject()
        throw(response);
      }
    })
    .catch(err => {
      console.log(err)
      reject()
    })
})

export const getExpiredLockersCount = (token, reset) => (dispatch) =>
new Promise(function(resolve, reject){
  if(reset){
    dispatch({
      type: "GET_EXPIRED_COUNT_SUCCESS",
      payload: false
    })
  }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get(`api/julian/getExpiredLockersCount`)
    .then(response => {
      if (response.data.ResultCode===1) {
        dispatch({
          type: "GET_EXPIRED_COUNT_SUCCESS",
          payload: expiredCounter(response.data.Data)
        })
        return resolve(response.data)
      } else {
        reject()
      }
    })
    .catch(err => {
      console.log(err)
      reject()
    })
})

export const getAlertLockersCount = (token, reset) => (dispatch) =>
new Promise(function(resolve, reject){
  if(reset){
    dispatch({
      type: "GET_ALERT_COUNT_SUCCESS",
      payload: false
    })
  }
    axios.defaults.headers.common = {'Authorization': 'Bearer ' + token}
    axios.get('api/julian/getOpenedOrAlertLockersCount')
    .then(response => {
      if (response.data.ResultCode===1) {
        dispatch({
          type: "GET_ALERT_COUNT_SUCCESS",
          payload: response.data.Data
        })
        resolve()
      } else {
        reject()
      }
    })
    .catch(err => {
      console.log(err)
      reject()
    })
})
