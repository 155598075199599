export const formatName = (name) => {
  if(name){
    const arr = name.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }

    const str2 = arr.join(" ");

    return str2
  }else{
    return ''
  }
}