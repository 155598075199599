const adminsReducer = (state = { holidayList: [], selectedAdmin: null, adminLogs: [] }, action) => {
  switch (action.type) {
    case "GET_HOLIDAYS_LIST_SUCCESS":
      return { ...state, holidayList: action.payload }
    default:
      return state
  }
}

export default adminsReducer
