import React from 'react';
import { connect } from "react-redux"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  Col,
  Row
} from "reactstrap"
import axios from "utility/api"
import { assignLocker } from "redux/actions/orders"
import { getLockersByPlace } from "redux/actions/locations"
import { toast } from "react-toastify"
import { withTranslation } from 'react-i18next'
import 'assets/scss/components/barcode-scanner.scss'
import ModalSpinner from "components/@vuexy/spinner/Modal-spinner"
import { rentalPlacesList } from 'configs/appConfig'
import Avatar from "components/@vuexy/avatar/AvatarComponent"
import { Box } from 'react-feather'
import { ColumnController } from 'ag-grid-community';

class DeliverOrderModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lockerSize: '',
      isSubmitting: false,
      selectedLocationId: 40,
      assignedLocker: false,
      validField: true
    }
  }

  componentDidMount() {
    this.props.dispatch(getLockersByPlace(this.props.accessToken, this.state.selectedLocationId)).then(() => {
      this.setState({ isSubmitting: false })
    }).catch(() => {
      toast.error(this.props.t("notifications.location.errorFetching"))
    })
  }

  setLockerSize = (lockerSize) => {
    this.setState({ lockerSize: lockerSize })
  }

  requestLocker = () => {
    if (this.state.lockerSize !== '') {
      this.setState({ isSubmitting: true })
      this.props.dispatch(assignLocker(this.props.accessToken, this.state.selectedLocationId, this.props.selectedOrder.packageId, this.state.lockerSize, true)).then((res) => {
        // if(res.Response === 'UPN not found.'){
        //   toast.error(this.props.t("notifications.deliverOrder.notificationSentError"))
        // }else{
        //   toast.success(this.props.t("notifications.deliverOrder.notificationSentSuccess"))
        // }
        this.setState({ isSubmitting: false })
      }).catch((err) => {
        this.setState({ isSubmitting: false })
        if (err === 'No locker available') {
          toast.error(this.props.t("notifications.deliverOrder.noLockersAvailable"))
        } else {
          toast.error(this.props.t("notifications.deliverOrder.errorRequestingLocker"))
        }
        this.props.toggleDeliveryModal()
      })
    } else {
      this.setState({ validField: false })
      //toast.error(this.props.t("notifications.deliverOrder.selectLockerSize"))
    }
  }

  openDoorAndDeliver = () => {
    this.setState({ isSubmitting: true })
    axios.defaults.headers.common = { 'Authorization': 'Bearer ' + this.props.accessToken }
    axios.get('api/delivery/storePackage?packageId=' + this.props.selectedOrder.packageId)
      .then(res => {
        this.setState({ isSubmitting: false })
        if (res.data.ResultCode === 1) {
          toast.success(this.props.t("notifications.deliverOrder.doorOpenedSuccessfuly"))
          this.props.toggleDeliveryModal()
        } else {
          toast.error(this.props.t("notifications.deliverOrder.errorOpeningDoor"))
        }
      })
      .catch(error => {
        this.setState({ isSubmitting: false })
        toast.error(this.props.t("notifications.deliverOrder.errorOpeningDoor"))
      })
  }

  changeLocation(id) {
    this.setState(
      {
        isSubmitting: true,
        selectedLocationId: id
      }
    )
    this.props.dispatch(getLockersByPlace(this.props.accessToken, id)).then(() => {
      this.setState({ isSubmitting: false })
    }).catch(() => {
      toast.error(this.props.t("notifications.location.errorFetching"))
    })
  }

  getLockersAvailableBySize = (size) => {
    return this.props?.lockersByPlace.filter(function (locker) {
      return (
        locker.enabled === 1 &&
        locker.status === 0 &&
        locker.locker_metra_status === 2 &&
        locker.size === size
      )
    });
  };

  toggleModal() {
    this.setState(
      {
        lockerSize: '',
        selectedLocationId: this.state.selectedLocationId,
        packageId: '',
        validField: true,
        assignedLocker: false,
      }
    )
    this.props.toggleDeliveryModal([])
  }

  validateFields = () => {
    if (this.state.packageId !== '') {
      this.setState({ validField: true })
    } else {
      this.setState({ validField: false })
    }
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const { t, selectedOrder } = this.props
    return (

      <Modal
        isOpen={this.props.showDeliveryModal}
        toggle={() => this.toggleModal()}
        className="modal-dialog-centered modal-lg"
      >
        <ModalHeader toggle={() => this.toggleModal()} className="bg-primary">
          {t('barcodeScanner.deliverOrder')}
        </ModalHeader>
        <ModalBody className="delivery-modal">
          {!selectedOrder &&
            <>
              <ModalSpinner />
            </>
          }
          {selectedOrder &&
            <>
              <p><strong>{t('orderDetails.packageId')}</strong><br />{selectedOrder.packageId}</p>
              <p><strong>{t('orderDetails.recipientName')}</strong><br />{selectedOrder.recipientName}</p>
              <p><strong>{t('users.upn')}</strong><br />{selectedOrder.assignedLockerUser?.login}</p>
              <h5 className="text-primary">
                {t('users.location')}:
              </h5>
              <Row className="px-1">
                {rentalPlacesList.map((item, index) =>
                  <Col key={index} xs="12" sm="12" md="12" lg="6">
                    <Card
                      text="info"
                      className={`p-1 px-2 cursor-pointer shadow-sm ${this.state.selectedLocationId === item.placeId ? 'border-danger shadow' : 'border-light'}`}
                      onClick={() => { this.changeLocation(item.placeId); this.setState({ lockerSize: '', validField: true }) }}
                    >
                      <Row className="d-flex align-items-center">
                        <Col sm="2" className="d-flex justify-content-center">
                          <Avatar
                            color='white'
                            border="danger"
                            icon={<Box color='#e41a15' />}
                            size="lg"
                          />
                        </Col>
                        <Col sm="3" className="d-flex justify-content-start">
                          {item.name}
                        </Col>
                        <Col sm="7" className="d-flex justify-content-between">
                          <small>{t("locations.floor")}
                            <span className="text-danger">
                              &nbsp;{item.floor}
                            </span>
                          </small>
                          <small>{t("locations.side")}
                            <span className="text-danger">
                              &nbsp;{item.side}
                            </span>
                          </small>
                          <small>{t("locations.zone")}
                            <span className="text-danger">
                              &nbsp;{item.zone}
                            </span>
                          </small>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                )}
              </Row>
              {!this.state.isSubmitting &&
                <>
                  <Row className="m-0 mb-1 px-1 d-flex justify-content-center">
                    <h5 className="text-primary">
                      {t('users.size')}:
                    </h5>
                  </Row>
                  <Row className="px-1 mb-1">
                    <Col xs="12" sm="6" md="6" lg="3">
                      <Card
                        className={`d-flex flex-row align-items-center justify-content-between p-1 px-2 ${this.getLockersAvailableBySize('S')?.length !== 0 ? 'cursor-pointer' : ''} shadow-sm ${this.state.lockerSize === "S" ? 'border-danger shadow' : 'border-light'}`}
                        onClick={() => this.getLockersAvailableBySize('S')?.length !== 0 ? this.setState({ lockerSize: 'S', validField: true }) : null}
                      >
                        <strong>
                          <span className={`${this.getLockersAvailableBySize('S')?.length === 0 ? 'text-muted' : ''}`}>S&ensp;</span>
                        </strong>
                        <small className="text-muted">
                          {this.getLockersAvailableBySize('S')?.length}&nbsp;{t('lockerStatus.available')}
                        </small>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="3">
                      <Card
                        className={`d-flex flex-row align-items-center justify-content-between p-1 px-2 ${this.getLockersAvailableBySize('M')?.length !== 0 ? 'cursor-pointer' : ''} shadow-sm ${this.state.lockerSize === "M" ? 'border-danger shadow' : 'border-light'}`}
                        onClick={() => this.getLockersAvailableBySize('M')?.length !== 0 ? this.setState({ lockerSize: 'M', validField: true }) : null}
                      >
                        <strong>
                          <span className={`${this.getLockersAvailableBySize('M')?.length === 0 ? 'text-muted' : ''}`}>M&ensp;</span>
                        </strong>
                        <small className="text-muted">
                          {this.getLockersAvailableBySize('M')?.length}&nbsp;{t('lockerStatus.available')}
                        </small>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="3">
                      <Card
                        className={`d-flex flex-row align-items-center justify-content-between p-1 px-2 ${this.getLockersAvailableBySize('L')?.length !== 0 ? 'cursor-pointer' : ''} shadow-sm ${this.state.lockerSize === "L" ? 'border-danger shadow' : 'border-light'}`}
                        onClick={() => this.getLockersAvailableBySize('L')?.length !== 0 ? this.setState({ lockerSize: 'L', validField: true }) : null}
                      >
                        <strong>
                          <span className={`${this.getLockersAvailableBySize('L')?.length === 0 ? 'text-muted' : ''}`}>L&ensp;</span>
                        </strong>
                        <small className="text-muted">
                          {this.getLockersAvailableBySize('L')?.length}&nbsp;{t('lockerStatus.available')}
                        </small>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="3">
                      <Card
                        className={`d-flex flex-row align-items-center justify-content-between p-1 px-2 ${this.getLockersAvailableBySize('XL')?.length !== 0 ? 'cursor-pointer' : ''} shadow-sm ${this.state.lockerSize === "XL" ? 'border-danger shadow' : 'border-light'}`}
                        onClick={() => this.getLockersAvailableBySize('XL')?.length !== 0 ? this.setState({ lockerSize: 'XL', validField: true }) : null}
                      >
                        <strong>
                          <span className={`${this.getLockersAvailableBySize('XL')?.length === 0 ? 'text-muted' : ''}`}>XL&ensp;</span>
                        </strong>
                        <small className="text-muted">
                          {this.getLockersAvailableBySize('XL')?.length}&nbsp;{t('lockerStatus.available')}
                        </small>
                      </Card>
                    </Col>
                  </Row>
                  {!this.state.validField ? (
                    <Row className="d-flex justify-content-center mb-1">
                      <span className="text-danger">{t('users.selectSize')}</span>
                    </Row>
                  ) : null}
                </>
              }
              {!selectedOrder.assignedLocker.number &&
                <Button color="primary" block onClick={() => this.requestLocker()}>
                  {t('barcodeScanner.requestLocker')}
                </Button>
              }
              {selectedOrder.assignedLocker.number && !this.state.isSubmitting &&
                <>
                  <Button color="warning" className="mb-1" disabled={this.state.isSubmitting} block onClick={() => this.requestLocker()}>
                    {t('barcodeScanner.changeLocker')}
                  </Button>
                  <p><strong>{t('barcodeScanner.assignedLocker')}</strong></p>
                  <p className="delivery-assigned-locker-number mt-2 mb-2">{selectedOrder.assignedLocker.number}</p>
                  <p>({t('orderDetails.size')}: {selectedOrder.assignedLocker.size})</p>
                  <Button color="primary" disabled={this.state.isSubmitting} block onClick={() => this.openDoorAndDeliver()}>
                    {t('barcodeScanner.confirmAndDeliver')}
                  </Button>
                </>
              }
            </>
          }
          {this.state.isSubmitting &&
            <Row className="d-flex h-100 align-items-center justify-content-center">
              <ModalSpinner />
            </Row>
          }
        </ModalBody>
      </Modal>

    )
  }
}

const mapStateToProps = state => {
  console.log(state)
  return {
    accessToken: state.auth.login.accessToken,
    selectedOrder: state.orders.selectedOrder,
    lockersBySize: state.locations.lockersBySize,
    lockersByPlace: state.locations.lockersByPlace.Lockers
  }
}

export default connect(mapStateToProps)(withTranslation('common')(DeliverOrderModal))
