import React from 'react';
import { 
  cleaningMode,
  emergencyMode,
  openLockerRequest,
  exportUsers } from "utility/requests"
import { toast } from "react-toastify"
import { connect } from "react-redux"
import { Mic } from "react-feather"
import { history } from "../../history"
import { getUsers } from "redux/actions/users"
import Artyom from 'artyom.js';

// Import the previously created class to handle the commands from another file
//import ArtyomCommandsManager from '../utility/voice-commands'

// Create a "globally" accesible instance of Artyom
const Jarvis = new Artyom();

class SpeechRecognition extends React.Component {
    constructor (props, context){
        super(props, context);

        // Add `this` context to the handler functions
        this.startAssistant = this.startAssistant.bind(this);
        this.stopAssistant = this.stopAssistant.bind(this);

        // Prepare simple state
        this.state = {
            artyomActive: false,
            artyomIsReading: false
        };

        // Load some commands to Artyom using the commands manager
        //let CommandsManager = new ArtyomCommandsManager(Jarvis);
        //CommandsManager.loadCommands();
        Jarvis.addCommands([
          {
              indexes: ["ativar modo de limpeza"],
              action: () => {
                cleaningMode(this.props.accessToken, "1").then(() => {
                  toast.success(this.props.t("notifications.globalOptions.cleaningModeActivate"))
                  Jarvis.say("Modo de limpeza ativado.")
                }).catch(() => {
                  toast.error(this.props.t("notifications.globalOptions.cleaningModeError"))
                  Jarvis.say("Não foi possível ativar o modo de limpeza");
                })
              }
          },
          {
              indexes: ["ativar modo de emergência"],
              action: () => {
                emergencyMode(this.props.accessToken, "1").then(() => {
                  toast.success(this.props.t("notifications.globalOptions.emergencyModeActivate"))
                  Jarvis.say("Modo de emergência ativado")
                }).catch(() => {
                  toast.error(this.props.t("notifications.globalOptions.emergencyModeActivateError"))
                  Jarvis.say("Não foi possível ativar o modo de emergência");
                })
              }
          },
          {
              indexes: ["exportar utilizadores"],
              action: () => {
                this.props.dispatch(getUsers(this.props.accessToken)).then(() => {
                    exportUsers(this.props.usersList).then(() => {
                      toast.success(this.props.t("notifications.voice.userListExported"))
                      Jarvis.say("Lista de utilizadores foi exportada.")
                    }).catch(() => {
                      toast.error(this.props.t("notifications.voice.userListExportError"))
                      Jarvis.say("Não foi possível exportar a lista de utilizadores");
                    })
                }).catch(() => {
                  toast.error(this.props.t("notifications.voice.getUsersError"))
                  Jarvis.say("Não foi possivel obter a lista de utilizadores.")
                })
              }
          },
          {
                indexes: ["Abre a porta *"],
                smart: true,
                action: (i, locker_id) => {
                  console.log('id captado na voz - ' + locker_id)
                  const metra_ids =  [ "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18" ]
                  openLockerRequest(this.props.accessToken, metra_ids[locker_id - 1]).then(() => {
                    toast.success(`${this.props.t("notifications.voice.locker")} ${locker_id} ${this.props.t("notifications.voice.hasBeenOpened")}.`)
                    Jarvis.say(`A porta ${locker_id} foi aberta.`)
                  }).catch(() => {
                    toast.error(this.props.t("notifications.voice.openLockerError"))
                    Jarvis.say("Não foi possível abrir a porta");
                  })
                }
            },
            {
                indexes: ["procurar utilizador *"],
                smart: true,
                action: (i, ccms) => {
                  //remove spaces from ccms string
                  ccms = ccms.replace(/\s/g, '')

                  this.props.dispatch(getUsers(this.props.accessToken)).then(() => {
                    //check for a user with matching ccms
                    var user = this.props.usersList.filter((item) => {
                      return item.ccms === ccms
                    })

                    history.push("/office-users/details/" + user[0].id)
                    Jarvis.say(`A redirecionar para os detalhes do utilizador ${user[0].name}`)
                  }).catch(() => {
                    toast.error(this.props.t("notifications.voice.getUsersError"))
                    Jarvis.say("Não foi possivel obter a lista de utilizadores.")
                  })
                }
            },
            {
                indexes: ["Listar *", "Navegar para *"],
                smart: true,
                action: (i, wildcard) => {
                  switch (wildcard) {
                    case 'utilizadores':
                      history.push("/office-users")
                      break;
                    case 'atividade':
                      history.push("/activity")
                      break;
                    case 'localizações':
                      history.push("/locations")
                      break;
                    case 'início':
                      history.push("/")
                      break;
                    default:
                      history.push("/")
                      break;
                  }
                }
            }
        ])
    }

    startAssistant() {
        let _this = this;

        console.log("Artyom succesfully started !");

        Jarvis.initialize({
            lang: "pt-PT",
            debug: true,
            continuous: true,
            soundex: true,
            listen: true,
            //name: "assistente"
        }).then(() => {
            // Display loaded commands in the console
            console.log(Jarvis.getAvailableCommands());

            Jarvis.say("Olá! Eu sou a Lock!");

            _this.setState({
                artyomActive: true
            });
        }).catch((err) => {
            console.error("Oopsy daisy, this shouldn't happen !", err);
        });
    }

    stopAssistant() {
        let _this = this;

        Jarvis.fatality().then(() => {
            console.log("Jarvis has been succesfully stopped");
            Jarvis.say("Tenha um bom dia.")
            _this.setState({
                artyomActive: false
            });

        }).catch((err) => {
            console.error("Oopsy daisy, this shouldn't happen neither!", err);

            _this.setState({
                artyomActive: false
            });
        });
    }

    toggleAssistant = () => {
      if(this.state.artyomActive){
        this.stopAssistant()
      }else{
        this.startAssistant()
      }
    }

    render() {
      var { artyomActive } = this.state
        return (
          <div onClick={this.toggleAssistant} className="cursor-pointer navbar-icon-wrapper">
            <Mic size={22} color={artyomActive ? "#EA5455" : "#35C76F"} className="navbar-icon"/>
          </div>
        )
    }
}

const mapStateToProps = state => {
  return {
      accessToken: state.auth.login.accessToken,
      selectedPlace: state.locations.selectedPlace,
      usersList: state.users.usersList
  }
}

export default connect(mapStateToProps)(SpeechRecognition)
